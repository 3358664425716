import * as $ from 'jquery';
import { showToast } from "/modules/toast";
import { showMenuSpinner, hideMenuSpinner } from "/modules/loader";
import serializeObject from "./serialize-object";
import { RequestVerificationToken } from '/js/utility/request-verification-token';
import {ApiResponse} from "/js/api/types";
import {loader} from "/modules/loader";


// note: use Models.ApiResponse for return value from action
const showSpinnerDefaultValue = true,
	reactSpinnerSelectorDefaultValue = "inner-spinner",
	spinnerSelectorDefaultValue = "#menu-loader-spinner",
	spinnerStartAfterDefaultValue = 0,
	wrapSuccessDefaultValue = true,
	wrapErrorDefaultValue = true,
	typeDefaultValue = "POST",
	cacheDefaultValue = false,
	contentTypeDefaultValue = "application/json; charset=utf-8";

type HeaderDictionary = {
	__RequestVerificationToken?: string;
	[header: string]: string;
}

// Options for ajax call
interface AjaxOptions<T> {
	// The url to call
	url?: string;
	// The data payload for the request.
	data?: object | string;

	// Form element as HTMLElement, JQuery object or selector. If not otherwise specified, data and url will be set from the form.
	form?: any; //Element | JQuery<Element> | JQuery.Selector;

	headers?: HeaderDictionary;

	// Whether to show a spinner while the call is in progress, default true.
	showSpinner?: boolean;
	// The title of the spinner (default nothing, only visible for full screen "loader").
	spinnerTitle?: string;
	// jQuery selector for the spinner, default "#menu-loader-spinner".
	spinnerSelector?: string | typeof loader;
	reactSpinnerSelector?: string; // "main-spinner" , "inner-spinner";
	// The class to toggle off/on for the spinner, default "is-hidden".
	spinnerToggleClass?: string;
	// Milliseconds after which the loader will show, default 0.
	spinnerStartAfter?: number;

	// Whether to wrap the success callback and look for ApiResponse "Error" and toast error message accordingly. Default true.
	wrapSuccess?: boolean;
	// Whether to wrap the error callback and toast the error message. Default true.
	wrapError?: boolean;

	// Http action, default "POST".
	type?: string;
	// Whether to allow cached responses, default false.
	cache?: boolean;
	// Content type for the request, default "application/json; charset=utf-8".
	contentType? : string;

	beforeSend?: (jqXhr: JQueryXHR, settings: object) => any;
	/** @deprecated On complete callback, regardless of success or error. Use returned promise .finally() instead. */
	complete?: (jqXhr: JQueryXHR, textStatus: string) => void;
	/** @deprecated On success callback. Use returned promise .then() instead. */
	success?: (data: ApiResponse<T>, textStatus: string, jqXHR: JQueryXHR) => void;
	/** @deprecated On error callback. Use returned promise .catch() instead. */
	error?: (jqXhr: JQueryXHR, textStatus: string, errorThrown: string) => void;
}

// Send an ajax Request
export function ajax<T = any>(options: AjaxOptions<T>) : Promise<ApiResponse<T>>
{
	// This should probably be done with $.extend...
	// Set default values
	options.showSpinner = typeof options.showSpinner === "undefined" ? showSpinnerDefaultValue : options.showSpinner;
	options.spinnerSelector = typeof options.spinnerSelector === "undefined" ? spinnerSelectorDefaultValue : options.spinnerSelector;
	options.reactSpinnerSelector = typeof options.reactSpinnerSelector === "undefined" ? reactSpinnerSelectorDefaultValue : options.reactSpinnerSelector;
	options.spinnerStartAfter = typeof options.spinnerStartAfter === "undefined" || !$.isNumeric(options.spinnerStartAfter) ? spinnerStartAfterDefaultValue : options.spinnerStartAfter;
	options.wrapSuccess = typeof options.wrapSuccess === "undefined" ? wrapSuccessDefaultValue : options.wrapSuccess;
	options.wrapError = typeof options.wrapError === "undefined" ? wrapErrorDefaultValue : options.wrapError;

	// AJAX default values
	options.data = typeof options.data === "object" ? JSON.stringify(options.data) : options.data;
	options.type = typeof options.type === "undefined" ? typeDefaultValue : options.type;
	options.cache = typeof options.cache === "undefined" ? cacheDefaultValue : options.cache;
	options.contentType = typeof options.contentType === "undefined" ? contentTypeDefaultValue : options.contentType;

	if (!options.headers) {
		options.headers = {};
	}
	if (!options.headers.__RequestVerificationToken) {
		options.headers.__RequestVerificationToken = RequestVerificationToken.__RequestVerificationToken;
	}

	if (options.form) {
		let $form = $(options.form);
		if ($form.is('form')) {
			const data = serializeObject($form);
			if (typeof options.data === "undefined") options.data = JSON.stringify(data);
			if (typeof options.url === "undefined") options.url = $form.attr('action');
			// TODO: This is probably pointless, because it is set above and will not be different
			if (!options.headers && data["__RequestVerificationToken"]) options.headers = { __RequestVerificationToken: data["__RequestVerificationToken"] };
		}
	}

	// ======================
	// BEFORE SEND AND COMPLETE
	// ======================
	if (options.showSpinner) {
		// BEFORE SEND
		const beforeSend = options.beforeSend;
		let timeout;

		options.beforeSend = function (jqXhr, settings) {
			timeout = setTimeout(function () {
				showMenuSpinner(options.spinnerSelector, options.spinnerTitle, options.spinnerToggleClass, options.reactSpinnerSelector);
			}, options.spinnerStartAfter);

			if (typeof beforeSend !== "undefined") {
				beforeSend(jqXhr, settings);
			}
		};

		// COMPLETE
		const complete = options.complete;

		options.complete = function (jqXhr, textStatus) {
			//! If there is an error in the "success" or "error" callback this will never be called!
			clearTimeout(timeout);
			hideMenuSpinner(options.spinnerSelector, options.spinnerToggleClass, options.reactSpinnerSelector);

			if (typeof complete !== "undefined") {
				complete(jqXhr, textStatus);
			}
		};
	}

	// ======================
	// SUCCESS
	// ======================
	if (options.wrapSuccess) {
		const success = options.success;

		options.success = function (data, textStatus, jqXhr) {
			if (data.Status === "Error") {
				showToast(data.Message, "error");
			}

			if (typeof success !== "undefined") {
				success(data, textStatus, jqXhr);
			}
		};
	}

	// ======================
	// ERROR
	// ======================
	if (options.wrapError) {
		const error = options.error;

		options.error = function (jqXhr, textStatus, errorThrown) {
			showToast(jqXhr.responseJSON?.Message || errorThrown || "An error occured", "error");

			if (typeof error !== "undefined") {
				error(jqXhr, textStatus, errorThrown);
			}
		};
	}

	return new Promise(function (resolve, reject) {
		$.ajax(options)
			.done(function (data, textStatus, jqXHR) {
				if (!options.wrapSuccess || data.Status === "Ok") {
					resolve(data);
				}
				if (data.Status === "Error")
				{
					reject(data);
				}
			})
			.fail(function (jqXHR, textStatus, errorThrown) {
				// This is a little weird, we can reject in two different ways with different parameters
				reject(jqXHR);
			});
	});
}
